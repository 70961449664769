
import * as Components from "./Italiano"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "italiano"
}

