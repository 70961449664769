import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import TopLogo from './svg/TopLogo'

import ThemeContext from '../../Context/ThemeContext'

import { Subscribe } from 'unstated'
import {injectCartContainer} from '../../react/CartContainer'
import {injectShopContainer} from '../../react/ShopDataContainer'
import {pages} from './index' 

function SiteNav ()  {
  

    return (
    <nav aria-label="secondary" className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      {/* <ThemeToggle></ThemeToggle> */}
    </nav>
  )
}

function CartMinimized ({cart, shop, settings}) {
  const {themeName} = useContext(ThemeContext)

  if (!shop) {return null}
  let items = cart.state.products
  let numberOfItems = items.length || 0
  let {
    logo,
  } = shop
  // console.log(shop.name)
  // const logoImage = makeCloudinaryUrlForPhoto(logo, {height: 200})
  const cashback = cart.state.cashback
  const showCashback = Number(cashback) && (cashback > 0)

  const logoURL = (themeName === 'dark') ?
  "https://afag.imgix.net/italianos-pizza/logo-bar.png?w=900&auto=format" :
  "https://afag.imgix.net/italianos-pizza/logo-bar.png?w=900&auto=format"
  return (
     <div role="navigation" aria-label="primary" className="cart-wrapper">
      {/* <div className="site-top-notificaiton">
        <h1>Thai City Restaurant is now: Charm — Thai Home Cooking</h1>
      </div> */}
      <div className="cart minimized">
        <div className="home-link-wrapper">
          <Link to="/"  className="home-link" aria-label="home page">
          {/* <TopLogo themeName={themeName}></TopLogo> */}
            <img
              className="nav-bar-logo"
              src={logoURL}
              alt="Pizzeria Factory"
            />
            {/* <LogoBar></LogoBar> */}
            {/* hi */}
            {/* <span className="text-logo">{!logo && shop.name && shop.name.en}</span> */}
          </Link>

        </div>

        <Link
            aria-label="menu"
            to={{pathname: "/menu", state: {fromMini: true}}}
            className="top-nav-link">MENU</Link>



        <div className="tool-bar">
          {/* {showCashback ? <span className="cashback-amount">
            ${cashback / 100}
          </span> : null} */}
       
          <Link
            aria-label="shopping cart"
            to={{pathname: "/checkout", state: {fromMini: true}}}
            className="checkout-button button">cart ({numberOfItems})</Link>
        </div>



      </div>


    </div>
  )
}




const CartIcon = () => <svg xmlns="http://www.w3.org/2000/svg" className="cart-icon" viewBox="0 0 24 24" version="1" width="50" height="50">
  <path d="M20 20v-8h2L12 3 2 12h2v8l1 1h5v-7h4v7h5l1-1z"/>
</svg>

export default (injectCartContainer(injectShopContainer(CartMinimized)))
